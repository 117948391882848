<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!user">
      <span class="loader"></span>
    </span>
    <div v-if="user" class="kw-tabs-wrapper">
      <div class="view-details-header">
        <div class="view-details-header-left">
          <h2>{{ $t("COMMON.USER_DETAILS") }}</h2>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img
                  src="../../../../../../public/img/kw-home.svg"
                  alt="icon"
                />
                <span>{{ $t("USERS.USERS_LIST") }}</span>
              </li>
              <li>
                <span>{{ `${user.firstname} ${user.lastname}` }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="view-details-header-right">
          <button class="delete" @click="deleteUser">
            <i class="far fa-trash-alt"></i>
            <span>{{ $t("COMMON.DELETE") }}</span>
          </button>
          <button class="edit" @click="editUser">
            <i class="far fa-edit"></i>
            <span>{{ $t("COMMON.EDIT") }}</span>
          </button>
        </div>
      </div>
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav kw-tabs"
        value="global"
      >
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            {{ $t("COMMON.GENERAL_INFORMATIONS") }}
          </span>
          <user-view-global :user="user" />
        </tab-pane>

        <tab-pane
          title="authorizations"
          id="2"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_PERMISSIONS)"
        >
          <span slot="title">
            {{ $t("USERS.AUTHORIZATIONS") }}
          </span>
          <user-view-authorizations :user="user" />
        </tab-pane>

        <tab-pane
          title="files"
          id="998"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_FILES) &&
            user.organization
          "
        >
          <span slot="title">
            {{ $t("COMMON.FILES") }}
          </span>
          <div class="elite-tabs-wrapper-content">
            <list-file-component :object="user" />
          </div>
        </tab-pane>

        <tab-pane
          title="logs"
          id="999"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            {{ $t("COMMON.LOGS") }}
          </span>
          <user-view-logs :user="user" />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { Tabs, TabPane } from "@/components";
import ListFileComponent from "@/components/File/ListFileComponent.vue";
import UserViewGlobal from "../partials/UserViewGlobal.vue";
import UserViewAuthorizations from "../partials/UserViewAuthorizations.vue";
import UserViewLogs from "../partials/UserViewLogs.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    UserViewGlobal,
    UserViewAuthorizations,
    UserViewLogs,
    ListFileComponent,
  },

  mixins: [requestErrorMixin],

  props: {
    userId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      user: null,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("users/get", this.userId);
        this.user = this.$store.getters["users/user"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async activateUser() {
      try {
        this.loading = true;
        await this.$store.dispatch("users/activate", this.user.id);
        this.user = this.$store.getters["users/user"];
        this.$notify({
          type: "success",
          message: this.$t("USERS.USER_ACTIVATED"),
        });
        this.loading = false;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async deactivateUser() {
      try {
        this.loading = true;
        await this.$store.dispatch("users/deactivate", this.user.id);
        this.user = this.$store.getters["users/user"];
        this.$notify({
          type: "success",
          message: this.$t("USERS.USER_DEACTIVATED"),
        });
        this.loading = false;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    editUser() {
      this.$emit("onEditUser", this.user);
    },

    deleteUser() {
      this.$emit("onDeleteUser", this.user);
    },
  },
};
</script>
